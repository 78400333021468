import gsap from "gsap"
import { ScrollTrigger } from "gsap/all"
import fundAnime from "../animations/fund.anime"
import sendAnime from "../animations/send.anime"
import receiveAnime from "../animations/receive.anime"
import airtimeAnime from "../animations/airtime.anime"
import earnAnime from "../animations/earn.anime"
import cardsAnime from "../animations/cards.anime"
import { $App } from ".."
import footerAnime from "../animations/footer.anime"
import homeAnime from "../animations/home.anime"

gsap.registerPlugin(ScrollTrigger)
gsap.registerEffect({
  name: "counter",
  extendTimeline: true,
  defaults: {
    end: 0,
    duration: 0.5,
    ease: "power1",
    increment: 1,
  },
  effect: (
    targets: HTMLElement,
    config: { duration: number; end: "number"; increment: number; ease: string }
  ) => {
    let tl = gsap.timeline()
    let num = targets[0].innerText.replace(/\,/g, "")
    targets[0].innerText = num
    tl.to(
      targets,
      {
        duration: config.duration,
        innerText: config.end,
        snap: {
          innerText: config.increment,
        },
        modifiers: {
          innerText: function (innerText) {
            return Math.floor(gsap.utils.snap(config.increment, innerText))
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
        ease: config.ease,
      },
      0
    )
    return tl
  },
})

export default class Home {
  app: $App
  layout: gsap.core.Timeline
  cardSections: HTMLElement[]
  cardColors: { bg: string; primary: string; secondary: string }[]
  previousIndex: number
  preText: HTMLElement
  constructor(app: $App) {
    this.app = app
  }

  create() {
    this.createLayout()
    this.createCards()
    this.createCTA()
  }

  resize() {
    if (innerWidth < 760) return
    this.layout.scrollTrigger.refresh()
    this.layout.revert()
    this.createLayout()
  }

  destroy() {}

  navigate() {
    this.create()
  }

  createLayout() {
    const mm = gsap.matchMedia()
    mm.add("(min-width:761px)", () => {
      this.layout = gsap
        .timeline({
          duration: 115,
          scrollTrigger: {
            trigger: ".home__features",
            start: "top 0%",
            end: "bottom+=900dvh 100%",
            pin: true,
            scrub: true,
          },
        })
        .add(homeAnime(), 0)
        .add(fundAnime().timeScale(1.15), 5)
        .add(sendAnime().timeScale(1.15), 20)
        .add(receiveAnime().timeScale(1.15), 35)
        .add(airtimeAnime().timeScale(1.15), 50)
        .add(cardsAnime().timeScale(1.15), 65)
        .add(earnAnime().timeScale(1.15), 80)
        .add(footerAnime().timeScale(1.15), 95)
        .add(gsap.to("footer", { border: "unset" }), 115)
    })

    mm.add("(max-width:760px)", () => {
      this.layout = gsap
        .timeline({
          duration: 65,
          scrollTrigger: {
            trigger: ".home__features",
            start: "top 0%",
            end: "bottom 100%",
            pin: true,
            scrub: true,
          },
        })
        .add(homeAnime().timeScale(0.85), 0)
        .add(fundAnime(), 5)
        .add(sendAnime(), 20)
        .add(receiveAnime(), 35)
        .add(airtimeAnime(), 50)
        .add(cardsAnime(), 65)
      // .add(earnAnime().timeScale(1.15), 80)
      // .add(footerAnime().timeScale(1.15), 91)
      // .add(gsap.to("footer", { border: "unset" }), 94)
    })
  }

  createCards() {
    this.previousIndex = 0
    this.cardSections = window.$$(".home__virtual")
    this.preText = window.$(".home__cards__head:nth-of-type(1)")
    this.cardColors = [
      { bg: "#164b60", primary: "#a2ff86", secondary: "#4fc0d0" },
      { bg: "#fffb73", primary: "#303030", secondary: "#b15eff" },
      { bg: "#84daff", primary: "#fff", secondary: "#138eb7" },
    ]
    const greenButtons = window.$$(
      ".home__cards > .figures > figure:nth-of-type(1)"
    )
    const purpleButtons = window.$$(
      ".home__cards > .figures > figure:nth-of-type(2)"
    )
    const blueButtons = window.$$(
      ".home__cards > .figures > figure:nth-of-type(3)"
    )

    greenButtons.forEach(
      (button) => (button.onclick = () => this.showSection(1))
    )
    purpleButtons.forEach(
      (button) => (button.onclick = () => this.showSection(2))
    )
    blueButtons.forEach(
      (button) => (button.onclick = () => this.showSection(3))
    )
  }

  showSection(index: number) {
    this.cardSections.forEach((section) => section.classList.remove("active"))
    this.cardSections[index].classList.add("active")
    gsap.to(".home__cards, .footer__skeleton__virtual", {
      background: this.cardColors[index - 1].bg,
      duration: 1,
      ease: "expo",
    })
    gsap.to(".home__cards__sub, .footer__skeleton__virtual *", {
      color: this.cardColors[index - 1].primary,
      duration: 0.5,
      ease: "expo.inout",
    })

    gsap.from(".home__virtual__dollar img", {
      autoAlpha: 0,
      rotate: () => `${(Math.random() - 0.5) * 120}vw`,
      y: () => `${Math.random() * 50 + 20}vh`,
      duration: 0.5,
      ease: "power2",
    })
    gsap.fromTo(
      ".home__virtual__ice img",
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 3,
      }
    )
    gsap.fromTo(
      ".home__virtual__ice img:nth-of-type(1)",
      {
        yPercent: -50,
      },
      {
        yPercent: 0,
        duration: 0.5,
      }
    )
    gsap.fromTo(
      ".home__virtual__ice img:nth-of-type(2)",
      {
        yPercent: 50,
      },
      {
        yPercent: 0,
        duration: 0.5,
      }
    )

    gsap.to(`.home__cards__head i, .home__cards__sub`, {
      opacity: 0,
      onComplete: () => {
        this.preText.innerText = ""
      },
    })
    gsap.to(
      `.home__cards__head:nth-of-type(${
        index + 1
      }) i, .home__cards__sub:nth-of-type(${index + 1})`,
      {
        opacity: 1,
        delay: 0.3,
      }
    )
    gsap.effects.counter(
      ".home__virtual__money div:nth-of-type(1) span, .home__virtual__money div:nth-of-type(2) span",
      {
        end: 2000,
        duration: 0,
        ease: "steps(1)",
      },
      0
    )
    gsap.effects.counter(
      ".home__virtual__money div:nth-of-type(1) span, .home__virtual__money div:nth-of-type(2) span",
      {
        end: 6500,
        duration: 0.5,
        ease: "steps(5)",
      },
      0
    )
    gsap.effects.counter(
      ".home__virtual__money div:nth-of-type(3) span",
      {
        end: 6500,
        duration: 0,
        ease: "steps(1)",
      },
      0
    )
    gsap.effects.counter(
      ".home__virtual__money div:nth-of-type(3) span",
      {
        end: 9200,
        duration: 0.5,
        ease: "steps(5)",
      },
      0
    )
    gsap.to(".header__popup", {
      background: this.cardColors[index - 1].secondary,
      duration: 0.5,
      ease: "expo.inout",
    })
    this.previousIndex = index
  }

  createCTA() {
    const ctas = window.$$(".home__hero__cta")
    ctas.forEach(
      (cta) =>
        (cta.onclick = () => {
          if (innerWidth < 760) window.scrollTo(0, innerHeight * 16)
          else {
            this.app.scroll.lenis.scrollTo(
              innerWidth < 760 ? innerHeight * 1.5 : innerHeight * 18.125,
              {
                immediate: true,
              }
            )
            this.app.scroll.lenis.scrollTo(
              innerWidth < 760 ? innerHeight * 1.5 : innerHeight * 18.5
            )
          }
        })
    )
  }
}
