import gsap from "gsap"
import { MotionPathPlugin } from "gsap/all"
gsap.registerPlugin(MotionPathPlugin)

export default function () {
  const mm = gsap.matchMedia()
  let tl: gsap.core.Timeline
  mm.add("(min-width:761px) and (max-width:1919px)", () => {
    tl = gsap
      .timeline()
      .from(
        ".home__airtime aside *",
        {
          color: "transparent",
        },
        10
      )
      .to(
        ".home__airtime__illustration > img:nth-of-type(1)",
        {
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: -50, y: -20 },
              { x: 50, y: -70 },
              { x: -50, y: -150 },
            ],
            type: "cubic",
          },
          opacity: 0,
          ease: "power1.inOut",
          duration: 7,
          delay: 3.5,
        },
        0
      )
      .to(
        ".home__airtime__illustration > img:nth-of-type(2)",
        {
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: 40, y: -20 },
              { x: -40, y: -100 },
              { x: 40, y: -200 },
            ],
            type: "cubic",
          },
          opacity: 0,
          ease: "power1.inOut",
          duration: 7.2,
          delay: 3.7,
        },
        0
      )
      .to(
        ".home__airtime__illustration > img:nth-of-type(3)",
        {
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: 30, y: -40 },
              { x: -30, y: -150 },
              { x: 30, y: -300 },
            ],
            type: "cubic",
          },
          opacity: 0,
          ease: "power1.inOut",
          duration: 7.5,
          delay: 3.5,
        },
        0
      )
      .to(
        ".home__airtime__illustration > img:nth-of-type(4)",
        {
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: 35, y: -100 },
              { x: -35, y: -200 },
              { x: 35, y: -300 },
            ],
            type: "cubic",
          },
          opacity: 0,
          ease: "power1.inOut",
          duration: 7.3,
          delay: 2.8,
        },
        0
      )
      .to(
        ".home__airtime__illustration > img:nth-of-type(5)",
        {
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: -20, y: -100 },
              { x: 20, y: -225 },
              { x: -20, y: -350 },
            ],
            type: "cubic",
          },
          opacity: 0,
          ease: "power1.inOut",
          duration: 6.5,
          delay: 3,
        },
        0
      )
      .fromTo(
        ".home__airtime__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)" },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .to(".home__airtime__sub", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .set(".footer div.footer__section__airtime", { autoAlpha: 1 }, 8)
      .set(".home__airtime aside > div", { autoAlpha: 0, delay: 8 }, 0)
      .to(".home__airtime", { autoAlpha: 0 }, 9)
      .from(
        ".footer div.footer__section__airtime",
        {
          top: "calc(-50vh + 9.5rem)",
          left: "-39.7rem",
          scale: 7,
          duration: 5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__airtime, .footer div.footer__section__airtime * ",
        {
          background: "transparent",
          color: "transparent",
          duration: 1,
          delay: 12,
        },
        0
      )
      .to(".footer", { y: "25%", duration: 5, delay: 8 }, 0)
      .to(".footer__skeleton__airtime", { opacity: 0, delay: 11.5 }, 0)
      .to(
        ".footer",
        {
          y: "85%",
          duration: 2,
        },
        13
      )
  })

  mm.add("(min-width:1920px)", () => {
    tl = gsap
      .timeline()
      .from(
        ".home__airtime aside *",
        {
          color: "transparent",
        },
        10
      )
      .to(
        ".home__airtime__illustration > img:nth-of-type(1)",
        {
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: -50, y: -20 },
              { x: 50, y: -70 },
              { x: -50, y: -150 },
            ],
            type: "cubic",
          },
          opacity: 0,
          ease: "power1.inOut",
          duration: 7,
          delay: 3.5,
        },
        0
      )
      .to(
        ".home__airtime__illustration > img:nth-of-type(2)",
        {
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: 40, y: -20 },
              { x: -40, y: -100 },
              { x: 40, y: -200 },
            ],
            type: "cubic",
          },
          opacity: 0,
          ease: "power1.inOut",
          duration: 7.2,
          delay: 3.7,
        },
        0
      )
      .to(
        ".home__airtime__illustration > img:nth-of-type(3)",
        {
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: 30, y: -40 },
              { x: -30, y: -150 },
              { x: 30, y: -300 },
            ],
            type: "cubic",
          },
          opacity: 0,
          ease: "power1.inOut",
          duration: 7.5,
          delay: 3.5,
        },
        0
      )
      .to(
        ".home__airtime__illustration > img:nth-of-type(4)",
        {
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: 35, y: -100 },
              { x: -35, y: -200 },
              { x: 35, y: -300 },
            ],
            type: "cubic",
          },
          opacity: 0,
          ease: "power1.inOut",
          duration: 7.3,
          delay: 2.8,
        },
        0
      )
      .to(
        ".home__airtime__illustration > img:nth-of-type(5)",
        {
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: -20, y: -100 },
              { x: 20, y: -225 },
              { x: -20, y: -350 },
            ],
            type: "cubic",
          },
          opacity: 0,
          ease: "power1.inOut",
          duration: 6.5,
          delay: 3,
        },
        0
      )
      .fromTo(
        ".home__airtime__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)" },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .to(".home__airtime__sub", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .set(".footer div.footer__section__airtime", { autoAlpha: 1 }, 8)
      .set(".home__airtime aside > div", { autoAlpha: 0, delay: 8 }, 0)
      .to(".home__airtime", { autoAlpha: 0 }, 9)
      .from(
        ".footer div.footer__section__airtime",
        {
          top: "calc(-50vh + 9.5rem)",
          left: "-101.7rem",
          scale: 7,
          duration: 5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__airtime, .footer div.footer__section__airtime * ",
        {
          background: "transparent",
          color: "transparent",
          duration: 1,
          delay: 12,
        },
        0
      )
      .to(".footer", { y: "25%", duration: 5, delay: 8 }, 0)
      .to(".footer__skeleton__airtime", { opacity: 0, delay: 11.5 }, 0)
      .to(
        ".footer",
        {
          y: "85%",
          duration: 2,
        },
        13
      )
  })

  mm.add("(max-width:760px)", () => {
    tl = gsap
      .timeline({ delay: 5 })
      .from(
        ".home__airtime aside *",
        {
          color: "transparent",
        },
        10
      )
      .to(
        ".home__airtime__illustration > img:nth-of-type(1)",
        {
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: -50, y: -20 },
              { x: 50, y: -70 },
              { x: -50, y: -150 },
            ],
            type: "cubic",
          },
          opacity: 0,
          ease: "power1.inOut",
          duration: 7,
          delay: 3.5,
        },
        0
      )
      .to(
        ".home__airtime__illustration > img:nth-of-type(6)",
        {
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: 40, y: -20 },
              { x: -40, y: -100 },
              { x: 40, y: -200 },
            ],
            type: "cubic",
          },
          opacity: 0,
          ease: "power1.inOut",
          duration: 7.2,
          delay: 3.7,
        },
        0
      )
      .to(
        ".home__airtime__illustration > img:nth-of-type(3)",
        {
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: 30, y: -40 },
              { x: -30, y: -150 },
              { x: 30, y: -300 },
            ],
            type: "cubic",
          },
          opacity: 0,
          ease: "power1.inOut",
          duration: 7.5,
          delay: 3.5,
        },
        0
      )
      .to(
        ".home__airtime__illustration > img:nth-of-type(5)",
        {
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: -20, y: -100 },
              { x: 20, y: -225 },
              { x: -20, y: -350 },
            ],
            type: "cubic",
          },
          opacity: 0,
          ease: "power1.inOut",
          duration: 6.5,
          delay: 3,
        },
        0
      )
      .fromTo(
        ".home__airtime__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)" },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .to(".home__airtime__sub", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .set(".footer div.footer__section__airtime", { autoAlpha: 1 }, 8)
      .set(".home__airtime aside > div", { autoAlpha: 0, delay: 8 }, 0)
      .to(".home__airtime", { autoAlpha: 0 }, 9)
      .from(
        ".footer div.footer__section__airtime",
        {
          top: "-162dvh",
          left: "45rem",
          scale: 18,
          duration: 5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__airtime, .footer div.footer__section__airtime * ",
        {
          background: "transparent",
          duration: 1,
          delay: 12,
        },
        0
      )
      .to(".footer", { y: "5%", duration: 2.5, delay: 10.5 }, 0)
      .set(".footer__section__airtime *", { color: "transparent", delay: 8 }, 0)
      .to(".footer__skeleton__airtime", { opacity: 0, delay: 11 }, 0)
      .to(
        ".footer",
        {
          y: "20%",
          duration: 2,
        },
        13
      )
  })

  return tl
}
