import gsap from "gsap"

export default function () {
  const mm = gsap.matchMedia()
  let tl: gsap.core.Timeline
  mm.add("(min-width:761px)", () => {
    gsap.to(".home__hero__clouds *", {
      x: "-120vw",
      repeat: -1,
      duration: 40,
      ease: "linear",
    })
    tl = gsap
      .timeline()
      .from(
        ".header__popup span",
        {
          background: "#836ee7",
        },
        0.35
      )
      .from(
        ".footer",
        {
          yPercent: 14,
        },
        0.35
      )
      .from(
        ".header__popup",
        {
          background: "#fff",
        },
        0.35
      )

      /* fund */
      .from(
        ".home__fund__illustration > figure",
        {
          y: "30vh",
          duration: 4,
        },
        1
      )

      /* send */
      .to(
        ".header__popup",
        {
          background: "#00322F",
        },
        12
      )
      .from(
        ".home__send__illustration > img:nth-of-type(2), .home__send__illustration > img:nth-of-type(3)",
        {
          y: () => `${Math.random() * 20 + 10}vh`,
          duration: 6,
        },
        15
      )
      .from(
        ".home__send__illustration > img:nth-of-type(1), .home__send__illustration > p",
        {
          y: () => `${Math.random() * 10 + 10}vh`,
          opacity: 0,
          duration: 6,
        },
        15
      )

      /* receive */
      .to(
        ".header__popup",
        {
          background: "#836EE7",
        },
        27
      )
      .from(
        ".home__receive__illustration > img",
        {
          y: () => `${Math.random() * 10 + 10}vh`,
          opacity: 0,
          duration: 6,
        },
        30
      )

      /* airtime */
      .to(
        ".header__popup",
        {
          background: "#F9A11E",
        },
        42
      )
      .from(
        ".home__airtime__illustration > img",
        {
          y: () => `${Math.random() * 15 + 15}vh`,
          opacity: 0,
          duration: 6,
        },
        45
      )

      /* cards */
      .to(
        ".header__popup",
        {
          background: "#7259EC",
        },
        57
      )
      .to(
        ".home__cards__head:nth-of-type(1) i",
        {
          opacity: 1,
          duration: 1,
          delay: 1.5,
        },
        0
      )
      .from(
        ".home__cards .figures > figure:nth-of-type(1)",
        {
          y: "7.3rem",
          opacity: 0,
          duration: 6,
        },
        60
      )
      .from(
        ".home__cards .figures > figure:nth-of-type(2)",
        {
          x: "4rem",
          y: "-3rem",
          opacity: 0,
          duration: 6,
        },
        60
      )
      .from(
        ".home__cards .figures > figure:nth-of-type(3)",
        {
          x: "-4rem",
          y: "-3rem",
          opacity: 0,
          duration: 6,
        },
        60
      )
      .from(
        ".home__virtual__illustration [quote]",
        {
          y: "10rem",
          opacity: 0,
          duration: 6,
        },
        60
      )

      /* earn */
      .to(
        ".header__popup",
        {
          background: "#7259EC",
        },
        72
      )
      .from(
        ".home__earn__feature:nth-of-type(1) [sub], .home__earn__feature:nth-of-type(2) [sub]",
        {
          xPercent: -100,
          clipPath: "inset(0% 0% 0% 100%)",
          duration: 2,
        },
        75
      )
      .from(
        ".home__earn__feature:nth-of-type(3) [sub]",
        {
          xPercent: 100,
          clipPath: "inset(0% 100% 0% 0%)",
          duration: 2,
        },
        75
      )

      /* footer */
      .fromTo(
        ".home__footer__pre i",
        {
          yPercent: 100,
          clipPath: "inset(0% 0% 100% 0%)",
        },
        {
          yPercent: 0,
          clipPath: "inset(-5% 0% 0% 0%)",
          duration: 1,
          delay: 2,
          stagger: { amount: 1 },
        },
        90
      )
      .from(
        ".home__footer .page__footer__buttons",
        { opacity: 0, duration: 0.6, delay: 2 },
        90
      )
  })

  mm.add("(max-width:760px)", () => {
    gsap.to(".home__hero__clouds *", {
      x: "-120vw",
      repeat: -1,
      duration: 35,
      ease: "linear",
    })

    tl = gsap
      .timeline()
      .from(
        ".header__popup span",
        {
          background: "#836ee7",
        },
        0.35
      )
      .from(
        ".footer",
        {
          yPercent: 14,
        },
        0.35
      )
      .from(
        ".header__popup",
        {
          background: "#fff",
        },
        0.35
      )
      .to(
        ".home__features",
        {
          background: "transparent",
        },
        0.35
      )

      /* fund */
      .from(
        ".home__fund__illustration > figure:nth-of-type(1)",
        {
          x: "-30vw",
          duration: 4,
        },
        1
      )
      .from(
        ".home__fund__illustration > figure:nth-of-type(2)",
        {
          x: "50vw",
          duration: 4,
        },
        1
      )

      /* send */
      .to(
        ".header__popup",
        {
          background: "#00322F",
        },
        12
      )
      .from(
        ".home__send__illustration > img, .home__send__illustration > p",
        {
          y: () => `${Math.random() * 20 + 10}vh`,
          opacity: 0,
          duration: 6,
        },
        15
      )

      /* receive */
      .to(
        ".header__popup",
        {
          background: "#836EE7",
        },
        27
      )
      .from(
        ".home__receive__illustration > img",
        {
          y: () => `${Math.random() * 10 + 10}vh`,
          opacity: 0,
          duration: 6,
        },
        30
      )

      /* airtime */
      .to(
        ".header__popup",
        {
          background: "#F9A11E",
        },
        42
      )
      .from(
        ".home__airtime__illustration > img",
        {
          y: () => `${Math.random() * 15 + 15}vh`,
          opacity: 0,
          duration: 6,
        },
        45
      )

      /* cards */
      .to(
        ".header__popup",
        {
          background: "#7259EC",
        },
        57
      )
      .to(
        ".home__cards__head:nth-of-type(1) i",
        {
          opacity: 1,
          duration: 1,
          delay: 1.5,
        },
        0
      )
      .from(
        ".home__cards .figures > figure:nth-of-type(1)",
        {
          y: "7.3rem",
          opacity: 0,
          duration: 4,
        },
        60
      )
      .from(
        ".home__cards .figures > figure:nth-of-type(2)",
        {
          x: "4rem",
          y: "-3rem",
          opacity: 0,
          duration: 4,
        },
        60
      )
      .from(
        ".home__cards .figures > figure:nth-of-type(3)",
        {
          x: "-4rem",
          y: "-3rem",
          opacity: 0,
          duration: 4,
        },
        60
      )
      .from(
        ".home__virtual__illustration [quote]",
        {
          y: "10rem",
          opacity: 0,
          duration: 6,
        },
        60
      )

      /* earn */
      .to(
        ".header__popup",
        {
          background: "#7259EC",
        },
        72
      )
      .from(
        ".home__earn__feature:nth-of-type(1) [icon], .home__earn__feature:nth-of-type(2) [icon]",
        {
          y: "25vh",
          opacity: 0,
          duration: 2.5,
        },
        74
      )
      .from(
        ".home__earn__feature:nth-of-type(3) [icon]",
        {
          y: "25vh",
          opacity: 0,
          duration: 2.5,
        },
        74
      )
      .from(
        ".home__earn__phone > img",
        {
          scale: 0.96,
          x: "0.2rem",
          duration: 2.5,
        },
        74
      )

      /* footer */
      .to(
        ".header__popup",
        {
          top: "1rem",
          duration: 2,
          ease: "ease.inout",
        },
        67
      )
  })
  return tl
}
