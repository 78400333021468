import gsap from "gsap"
import Framework from "./classes/framework"
import Scroll from "./classes/scroll"
import Home from "./pages/home"
import Nav from "./partials/nav"
import Company from "./pages/company"
import PrivacyPolicy from "./pages/privacy-policy"
import TermsOfUse from "./pages/terms-of-use"
import Footer from "./partials/footer"
import Faqs from "./pages/faqs"
import { images } from "./classes/assets"

declare global {
  interface Window {
    app: App
    $(el: string): HTMLElement
    $$(el: string): HTMLElement[]
  }
}

export interface $App {
  page: Home
  pages: {
    home: Home
    company: Company
    faqs: Faqs
    privacyPolicy: PrivacyPolicy
    termsOfUse: TermsOfUse
  }
  partials: { nav: Nav; footer: Footer }
  scroll: Scroll
  content: any
  template: any
  createPages(): void
  createPartials(): void
}

class App extends Framework implements $App {
  pages: {
    home: Home
    company: Company
    faqs: Faqs
    privacyPolicy: PrivacyPolicy
    termsOfUse: TermsOfUse
  }
  partials: { nav: Nav; footer: Footer }
  page: any

  constructor() {
    super()
    this.createPages()
    this.createPartials()
    this.preload()
    window.onresize = () => this.onresize()
  }

  preload() {
    const length = images.length
    let counter = 1
    images.forEach((image) => {
      const img = new Image()
      img.src = image
      img.crossOrigin = "anonymous"
      img.onload = () => {
        if (counter === length) {
          window.$(".app").classList.remove("preloading")
        }
        counter++
      }
    })
  }

  createPartials() {
    this.partials = {
      nav: new Nav(this),
      footer: new Footer(this),
    }
  }
  createPages() {
    this.pages = {
      home: new Home(this),
      company: new Company(this),
      faqs: new Faqs(this),
      privacyPolicy: new PrivacyPolicy(this),
      termsOfUse: new TermsOfUse(this),
    }
    this.page = this.pages[this.template]
    this.page.create()
  }

  onresize() {
    Object.values(this.pages).forEach((page) => page.resize())
    Object.values(this.partials).forEach((partial) => partial.resize())
  }
}

window.$ = (el: string) => document.querySelector(el)
window.$$ = (el: string) => gsap.utils.toArray(el)
window.app = new App()
