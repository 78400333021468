import gsap from "gsap"

export default function () {
  const mm = gsap.matchMedia()
  let tl: gsap.core.Timeline
  mm.add("(min-width:761px)", () => {
    tl = gsap
      .timeline()
      .fromTo(
        ".home__footer__pre,  .home__footer .page__footer__buttons",
        {
          y: "0",
        },
        {
          y: "-75dvh",
          opacity: 0,
          duration: 4,
        },
        4
      )
      .fromTo(
        ".home__footer .home__hero > h1, .home__footer .home__hero > p, .home__footer .home__hero > button",
        {
          y: "100vh",
        },
        {
          y: "0",
          duration: 5,
        },
        5
      )
      .fromTo(
        ".home__footer .home__hero__icons > *",
        {
          y: () => `${Math.random() * 75 + 75}dvh`,
        },
        {
          y: "0",
          duration: 5,
        },
        5
      )
      .fromTo(
        ".home__footer .home__hero",
        {
          background: "linear-gradient(180deg,#c3b7ff 65.91%,#c3b7ff 96.3%)",
          duration: 5,
        },
        {
          background:
            "linear-gradient(180deg,#826ee6 65.91%,rgba(130, 110, 230, 0) 96.3%)",
          duration: 5,
        },
        5
      )
      .to(
        ".footer > img",
        {
          opacity: 0,
        },
        5
      )
      .to(
        ".footer",
        {
          filter: "blur(5rem)",
          opacity: 0,
          duration: 4,
          y: "-20rem",
        },
        5
      )
      .to(
        ".header__popup span",
        {
          background: "#836ee7",
        },
        5.35
      )
      .to(
        ".header__popup",
        {
          background: "#fff",
        },
        5.35
      )
  })

  mm.add("(max-width:760px)", () => {
    tl = gsap
      .timeline()
      .set(
        ".footer__section__fund h5, footer__section__ h6",
        {
          opacity: 1,
          color: "#fff",
        },
        0
      )
      .set(
        ".footer__section__fund h5, .footer__section__fund button:nth-of-type(2)",
        {
          opacity: 1,
          color: "#fff",
        },
        0
      )
      .set(
        ".footer__section__fund h4, .footer__section__fund h4 *",
        {
          opacity: 1,
          color: "#fefefe",
        },
        0
      )
      .set(
        ".footer__section__fund button:nth-of-type(1)",
        {
          opacity: 1,
          color: "#1d1d1d",
        },
        0
      )
      .set(
        ".footer__section__send h3, .footer__section__receive h3, .footer__section__airtime h3",
        {
          opacity: 1,
          color: "#6246EA",
        },
        0
      )
      .set(
        ".footer__section__send h6, .footer__section__receive h6, .footer__section__airtime h6",
        {
          opacity: 1,
          color: "#4F4F4F",
        },
        0
      )
      .set(
        ".footer__skeleton__virtual h3, .footer__skeleton__virtual h6",
        {
          opacity: 1,
        },
        0
      )
      .set(
        ".footer__section__earn h3",
        {
          opacity: 1,
          color: "#453D90",
        },
        0
      )
      .set(
        ".footer__section__earn h6",
        {
          opacity: 1,
          color: "#061C40",
          width: "8.7485rem",
        },
        0
      )
  })

  return tl
}
