import gsap from "gsap"
import { Draggable } from "gsap/all"
import { $App } from ".."
import { navOpen } from "../animations/nav.anime"

gsap.registerPlugin(Draggable)

export default class Nav {
  app: $App
  element: any
  timeline: gsap.core.Timeline
  isMenuOpen: boolean
  elements: HTMLElement[]
  pages: {
    home: string
    company: string
    faqs: string
    privacyPolicy: string
    termsOfUse: string
  }
  titles: {
    home: string
    company: string
    faqs: string
    "terms-of-use": string
    "privacy-policy": string
  }
  constructor(app: $App) {
    this.app = app
    this.create()
    this.pages = {
      home: "",
      company: "company",
      faqs: "faqs",
      privacyPolicy: "privacy-policy",
      termsOfUse: "terms-of-use",
    }
    this.titles = {
      home: "Nyatwa — Move Money Like Never Before",
      company: "Nyatwa — Company",
      faqs: "Nyatwa — FAQS",
      "terms-of-use": "Nyatwa — Terms",
      "privacy-policy": "Nyatwa — Policy",
    }
  }

  create() {
    window.addEventListener("popstate", this.back.bind(this))

    this.element = window.$(".header__popup")
    this.element.onclick = () => {
      this.isMenuOpen ? this.menuClose() : this.menuOpen()
    }
    this.timeline = navOpen()

    this.elements = window.$$(".header__menu__link")
    this.elements.forEach((element, index) => {
      if (innerWidth < 760) return
      if (index === 3) return
      if (index === 1) {
        element.onclick = async (e) => {
          e.preventDefault()
          if (this.app.template === "home") {
            await this.menuClose()
            if (this.app.scroll.lenis.progress < 0.1)
              this.app.scroll.lenis.scrollTo(innerHeight, {})
            if (this.app.scroll.lenis.progress > 0.9)
              this.app.scroll.lenis.scrollTo(innerHeight * 16, {})
            return
          }
          const target = e.target as HTMLAnchorElement
          await this.ready(target.href, true)
        }
      } else {
        element.onclick = async (e) => {
          e.preventDefault()
          const target = e.target as HTMLAnchorElement
          await this.ready(target.href, true)
        }
      }
    })

    Draggable.create(".header__menu__icons img", {
      inertia: true,
    })
  }

  resize() {}
  destroy() {}

  navigate() {
    this.create()
  }

  async ready(href: string, push = true) {
    const [html, template] = await this.go(event)
    this.app.page.destroy()
    Object.values(this.app.partials).forEach((partial) => partial.destroy())
    this.app.content.innerHTML = html
    this.app.content.setAttribute("data-template", template)
    this.app.template = this.app.content.getAttribute("data-template")
    push && history.pushState({}, "", this.pages[template])
    this.app.page = this.app.pages[this.app.template]
    this.app.page.navigate()
    this.app.scroll.navigate(this.app.template)
    Object.values(this.app.partials).forEach((partial) => partial.navigate())
    document.title = this.titles[this.pages[this.app.template]]
    this.app.scroll.lenis.scrollTo(0, { immediate: true })
    this.menuClose()
  }

  async go({ target }) {
    const { href } = target
    const request = await fetch(href)
    if (request.ok) {
      const html = await request.text()
      const div = document.createElement("div")
      div.innerHTML = html
      const content = div.querySelector(".content")
      const template = content.getAttribute("data-template")
      return [content.innerHTML, template]
    } else {
      console.log(`could not fetch ${href}`)
    }
  }

  async back() {
    if (innerWidth < 760) return
    location.reload()
  }

  menuOpen() {
    this.timeline.play()
    this.isMenuOpen = true
    this.app.scroll.lenis.stop()
  }

  async menuClose() {
    await this.timeline.reverse()
    this.timeline.invalidate()
    this.isMenuOpen = false
    this.app.scroll.lenis.start()
  }
}
