import gsap from "gsap"

export default function () {
  const mm = gsap.matchMedia()
  let tl: gsap.core.Timeline
  mm.add("(min-width:761px) and (max-width:1919px)", () => {
    tl = gsap
      .timeline()
      .from(
        ".home__send aside *",
        {
          color: "transparent",
        },
        10
      )
      .to(
        ".home__send__illustration > img:nth-of-type(1)",
        { scale: 0.5, duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__send__illustration > img:nth-of-type(2)",
        { x: "-50vw", duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__send__illustration > img:nth-of-type(3)",
        { x: "50vw", duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__send__illustration > p",
        {
          color: "transparent",
          duration: 1.5,
          delay: 4,
          stagger: { amount: 0.5 },
        },
        0
      )
      .to(
        ".home__send__illustration > p",
        {
          autoAlpha: 0,
          duration: 1.5,
          delay: 6,
          stagger: { amount: 0.5 },
        },
        0
      )
      .to(
        ".home__send__illustration > img:nth-of-type(1)",
        { clipPath: "inset(0% 0% 100% 0%)", duration: 1.5, delay: 6 },
        0
      )
      .fromTo(
        ".home__send__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)" },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .to(".home__send__sub", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .set(".footer div.footer__section__send", { autoAlpha: 1 }, 8)
      .set(".home__send aside > div", { autoAlpha: 0, delay: 8 }, 0)
      .to(".home__send", { autoAlpha: 0 }, 9)
      .from(
        ".footer div.footer__section__send",
        {
          top: "calc(-50vh + 13rem)",
          left: "-43.388rem",
          scale: 6,
          duration: 5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__send, .footer div.footer__section__send * ",
        {
          background: "transparent",
          color: "transparent",
          duration: 1,
          delay: 12,
        },
        0
      )
      .to(".footer", { y: "50%", duration: 5, delay: 8 }, 0)
      .to(".footer__skeleton__send", { opacity: 0, delay: 11 }, 0)
      .to(
        ".footer",
        {
          y: "85%",
          duration: 2,
        },
        13
      )
  })

  mm.add("(min-width:1920px)", () => {
    tl = gsap
      .timeline()
      .from(
        ".home__send aside *",
        {
          color: "transparent",
        },
        10
      )
      .to(
        ".home__send__illustration > img:nth-of-type(1)",
        { scale: 0.5, duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__send__illustration > img:nth-of-type(2)",
        { x: "-50vw", duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__send__illustration > img:nth-of-type(3)",
        { x: "50vw", duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__send__illustration > p",
        {
          color: "transparent",
          duration: 1.5,
          delay: 4,
          stagger: { amount: 0.5 },
        },
        0
      )
      .to(
        ".home__send__illustration > p",
        {
          autoAlpha: 0,
          duration: 1.5,
          delay: 6,
          stagger: { amount: 0.5 },
        },
        0
      )
      .to(
        ".home__send__illustration > img:nth-of-type(1)",
        { clipPath: "inset(0% 0% 100% 0%)", duration: 1.5, delay: 6 },
        0
      )
      .fromTo(
        ".home__send__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)" },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .to(".home__send__sub", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .set(".footer div.footer__section__send", { autoAlpha: 1 }, 8)
      .set(".home__send aside > div", { autoAlpha: 0, delay: 8 }, 0)
      .to(".home__send", { autoAlpha: 0 }, 9)
      .from(
        ".footer div.footer__section__send",
        {
          top: "calc(-50vh + 13rem)",
          left: "-105.788rem",
          scale: 6,
          duration: 5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__send, .footer div.footer__section__send * ",
        {
          background: "transparent",
          color: "transparent",
          duration: 1,
          delay: 12,
        },
        0
      )
      .to(".footer", { y: "50%", duration: 5, delay: 8 }, 0)
      .to(".footer__skeleton__send", { opacity: 0, delay: 11 }, 0)
      .to(
        ".footer",
        {
          y: "85%",
          duration: 2,
        },
        13
      )
  })

  mm.add("(max-width:760px)", () => {
    tl = gsap
      .timeline({ delay: 5 })
      .from(
        ".home__send aside *",
        {
          color: "transparent",
        },
        10
      )
      .to(
        ".home__send__illustration > img:nth-of-type(1)",
        { x: "50vw", duration: 1.5, delay: 3.2 },
        0
      )
      .to(
        ".home__send__illustration > img:nth-of-type(4)",
        { x: "-75vw", duration: 1.5, delay: 3.2 },
        0
      )
      .to(
        ".home__send__illustration > img:nth-of-type(5)",
        { x: "-50vw", duration: 1.5, delay: 3.2 },
        0
      )
      .to(
        ".home__send__illustration > p",
        {
          color: "transparent",
          duration: 1.5,
          delay: 3.2,
          stagger: { amount: 0.5 },
        },
        0
      )
      .to(
        ".home__send__illustration > p",
        {
          autoAlpha: 0,
          duration: 1.5,
          delay: 5.2,
          stagger: { amount: 0.5 },
        },
        0
      )
      .fromTo(
        ".home__send__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)" },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .to(".home__send__sub", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .set(".footer div.footer__section__send", { autoAlpha: 1 }, 8)
      .set(".home__send aside > div", { autoAlpha: 0, delay: 8 }, 0)
      .to(".home__send", { autoAlpha: 0 }, 9)
      .from(
        ".footer div.footer__section__send",
        {
          top: "-147dvh",
          left: "32.8rem",
          scale: 16.2,
          duration: 5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__send, .footer div.footer__section__send * ",
        {
          background: "transparent",
          duration: 1,
          delay: 12,
        },
        0
      )
      .to(".footer", { y: "10%", duration: 2.5, delay: 10.5 }, 0)
      .set(".footer__section__send *", { color: "transparent", delay: 8 }, 0)
      .to(".footer__skeleton__send", { opacity: 0, delay: 11 }, 0)
      .to(
        ".footer",
        {
          y: "20%",
          duration: 2,
        },
        13
      )
  })

  return tl
}
