import { $App } from ".."

export default class Faqs {
  app: $App
  faqs: HTMLElement[]
  constructor(app: $App) {
    this.app = app
  }

  create() {
    this.faqs = window.$$(".faqs__hero__faq")
    this.faqs.forEach(
      (faq, index) =>
        (faq.onclick = () => {
          this.onToggle(faq, index)
        })
    )
  }

  resize() {}

  destroy() {}

  navigate() {
    this.create()
  }

  onToggle(faq: HTMLElement, index: number) {
    if (this.faqs[index].classList.contains("active")) {
      this.faqs[index].classList.remove("active")
      return
    } else {
      this.faqs.forEach((faq) => faq.classList.remove("active"))
      this.faqs[index].classList.add("active")
    }
  }
}
