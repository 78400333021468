import gsap from "gsap"

gsap.registerEffect({
  name: "counter",
  extendTimeline: true,
  defaults: {
    end: 0,
    duration: 0.5,
    ease: "power1",
    increment: 1,
  },
  effect: (
    targets: HTMLElement,
    config: { duration: number; end: "number"; increment: number; ease: string }
  ) => {
    let tl = gsap.timeline()
    let num = targets[0].innerText.replace(/\,/g, "")
    targets[0].innerText = num
    tl.to(
      targets,
      {
        duration: config.duration,
        innerText: config.end,
        snap: {
          innerText: config.increment,
        },
        modifiers: {
          innerText: function (innerText) {
            return Math.floor(gsap.utils.snap(config.increment, innerText))
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
        ease: config.ease,
      },
      6.5
    )
    return tl
  },
})

export default function () {
  const mm = gsap.matchMedia()
  let tl: gsap.core.Timeline
  mm.add("(min-width:761px) and (max-width:1919px)", () => {
    tl = gsap
      .timeline()
      .from(
        ".home__earn aside *",
        {
          color: "transparent",
        },
        10
      )
      .counter(
        ".home__earn__phone .home__earn__wallet h4 span:nth-of-type(1)",
        {
          end: 200311,
          duration: 1.2,
          ease: "steps(10)",
        },
        0
      )
      .to(
        ".home__earn__feature:nth-of-type(1) [sub], .home__earn__feature:nth-of-type(2) [sub]",
        {
          xPercent: -100,
          clipPath: "inset(0% 0% 0% 100%)",
          duration: 2,
          delay: 4.5,
        },
        0
      )
      .to(
        ".home__earn__feature:nth-of-type(3) [sub]",
        {
          xPercent: 100,
          clipPath: "inset(0% 100% 0% 0%)",
          duration: 2,
          delay: 4.5,
        },
        0
      )
      .to(
        ".home__earn__phone > img:nth-of-type(1)",
        {
          scale: 0.63,
          transformOrigin: "60%",
          duration: 2,
          delay: 4.5,
        },
        0
      )
      .to(
        ".home__earn__phone > img:nth-of-type(1)",
        {
          yPercent: 50,
          clipPath: "inset(0% 0% 100% 0%)",
          duration: 2,
          delay: 6.5,
        },
        0
      )
      .to(
        ".home__earn__feature [icon]",
        {
          opacity: 0,
          duration: 1.5,
          delay: 6.5,
        },
        0
      )
      .from(
        ".home__earn aside",
        {
          opacity: 0,
          duration: 1.5,
          delay: 6.5,
        },
        0
      )
      .fromTo(
        ".home__earn__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)" },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .to(".home__earn__sub", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .set(".footer div.footer__section__earn", { autoAlpha: 1 }, 8)
      .set(".home__earn aside > div", { autoAlpha: 0, delay: 8 }, 0)
      .to(".home__earn", { autoAlpha: 0, duration: 2 }, 10)
      .from(
        ".footer div.footer__section__earn",
        {
          top: "calc(-50vh + 9.5rem)",
          left: "-90rem",
          scale: 3,
          duration: 5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__earn img:nth-of-type(1)",
        {
          top: "-10.7rem",
          left: "-3.1rem",
          duration: 2,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__earn img:nth-of-type(2)",
        {
          top: "-2.9rem",
          left: "-27.9em",
          duration: 1.5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__earn span",
        {
          top: "0.25rem",
          left: "4.1rem",
          duration: 1,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__earn, .footer div.footer__section__earn > * ",
        {
          background: "transparent",
          color: "transparent",
          duration: 1,
          delay: 12,
        },
        0
      )
      .to(".footer", { y: "0%", duration: 5, delay: 8 }, 0)
      .to(".footer__skeleton__earn", { opacity: 0, delay: 11.5 }, 0)
      .to(
        ".footer",
        {
          left: "calc(50vw - 12.25rem)",
          bottom: "calc(50vh - 25rem)",
          scale: 1.444,
          duration: 2,
        },
        13
      )
  })

  mm.add("(min-width:1920px)", () => {
    tl = gsap
      .timeline()
      .from(
        ".home__earn aside *",
        {
          color: "transparent",
        },
        10
      )
      .counter(
        ".home__earn__phone .home__earn__wallet h4 span:nth-of-type(1)",
        {
          end: 200311,
          duration: 1.2,
          ease: "steps(10)",
        },
        0
      )
      .to(
        ".home__earn__feature:nth-of-type(1) [sub], .home__earn__feature:nth-of-type(2) [sub]",
        {
          xPercent: -100,
          clipPath: "inset(0% 0% 0% 100%)",
          duration: 2,
          delay: 4.5,
        },
        0
      )
      .to(
        ".home__earn__feature:nth-of-type(3) [sub]",
        {
          xPercent: 100,
          clipPath: "inset(0% 100% 0% 0%)",
          duration: 2,
          delay: 4.5,
        },
        0
      )
      .to(
        ".home__earn__phone > img:nth-of-type(1)",
        {
          scale: 0.63,
          transformOrigin: "60%",
          duration: 2,
          delay: 4.5,
        },
        0
      )
      .to(
        ".home__earn__phone > img:nth-of-type(1)",
        {
          yPercent: 50,
          clipPath: "inset(0% 0% 100% 0%)",
          duration: 2,
          delay: 6.5,
        },
        0
      )
      .to(
        ".home__earn__feature [icon]",
        {
          opacity: 0,
          duration: 1.5,
          delay: 6.5,
        },
        0
      )
      .from(
        ".home__earn aside",
        {
          opacity: 0,
          duration: 1.5,
          delay: 6.5,
        },
        0
      )
      .fromTo(
        ".home__earn__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)" },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .to(".home__earn__sub", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .set(".footer div.footer__section__earn", { autoAlpha: 1 }, 8)
      .set(".home__earn aside > div", { autoAlpha: 0, delay: 8 }, 0)
      .to(".home__earn", { autoAlpha: 0, duration: 2 }, 10)
      .from(
        ".footer div.footer__section__earn",
        {
          top: "calc(-50vh + 9.5rem)",
          left: "-214rem",
          scale: 3,
          duration: 5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__earn img:nth-of-type(1)",
        {
          top: "-10.7rem",
          left: "-3.1rem",
          duration: 2,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__earn img:nth-of-type(2)",
        {
          top: "-2.9rem",
          left: "-27.9em",
          duration: 1.5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__earn span",
        {
          top: "0.25rem",
          left: "4.1rem",
          duration: 1,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__earn, .footer div.footer__section__earn > * ",
        {
          background: "transparent",
          color: "transparent",
          duration: 1,
          delay: 12,
        },
        0
      )
      .to(".footer", { y: "0%", duration: 5, delay: 8 }, 0)
      .to(".footer__skeleton__earn", { opacity: 0, delay: 11.5 }, 0)
      .to(
        ".footer",
        {
          left: "calc(50vw - 12.25rem)",
          bottom: "calc(50vh - 25rem)",
          scale: 1.444,
          duration: 2,
        },
        13
      )
  })

  mm.add("(max-width:760px)", () => {
    tl = gsap.timeline().to(
      ".home__earn",
      {
        y: "-100vh",
        duration: 5,
      },
      0
    )
    /* tl = gsap
      .timeline()
      .from(
        ".home__earn aside *",
        {
          color: "transparent",
        },
        10
      )
      .counter(
        ".home__earn__phone .home__earn__wallet h4 span:nth-of-type(1)",
        {
          end: 200311,
          duration: 1.2,
          ease: "steps(10)",
        },
        0
      )
      .to(
        ".home__earn__feature:nth-of-type(1) [sub], .home__earn__feature:nth-of-type(2) [sub]",
        {
          xPercent: -100,
          clipPath: "inset(0% 0% 0% 100%)",
          duration: 2,
          delay: 4.5,
        },
        0
      )
      .to(
        ".home__earn__feature:nth-of-type(3) [sub]",
        {
          xPercent: 100,
          clipPath: "inset(0% 100% 0% 0%)",
          duration: 2,
          delay: 4.5,
        },
        0
      )
      .to(
        ".home__earn__phone > img:nth-of-type(1)",
        {
          scale: 0.63,
          transformOrigin: "60%",
          duration: 2,
          delay: 4.5,
        },
        0
      )
      .to(
        ".home__earn__phone > img:nth-of-type(1)",
        {
          yPercent: 52,
          clipPath: "inset(0% 0% 100% 0%)",
          duration: 2,
          delay: 6.5,
        },
        0
      )
      .to(
        ".home__earn__feature [icon]",
        {
          opacity: 0,
          duration: 1.5,
          delay: 6.5,
        },
        0
      )
      .from(
        ".home__earn aside",
        {
          opacity: 0,
          duration: 1.5,
          delay: 6.5,
        },
        0
      )
      .fromTo(
        ".home__earn__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)" },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .to(".home__earn__sub", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .to(
        ".footer div.footer__section__earn, .home__earn__phone",
        { autoAlpha: 0 },
        8
      )
      .set(".footer div.footer__section__earn > *", { color: "transparent" }, 6)
      .set(".home__earn aside > div", { autoAlpha: 0, delay: 2 }, 0)
      .to(".home__earn", { autoAlpha: 0 }, 9)
      .from(
        ".footer div.footer__section__earn, .footer div.footer__section__earn > * ",
        {
          background: "transparent",
          duration: 1,
          delay: 12,
        },
        0
      )
      .from(
        ".footer div.footer__section__earn img:nth-of-type(1)",
        {
          top: "-13.7rem",
          left: "-7.635rem",
          duration: 2,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__earn img:nth-of-type(2)",
        {
          top: "-11.6rem",
          left: "-1.4em",
          duration: 1.5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__earn span",
        {
          top: "-1.45rem",
          left: "-1.975rem",
          duration: 1,
          delay: 8,
        },
        0
      )
      .to(".footer", { autoAlpha: 0, duration: 0.5, delay: 5.5 }, 0)
      .set(".footer__section__earn *", { color: "transparent", delay: 8 }, 0)
      .to(".footer__skeleton__earn", { opacity: 0, delay: 11 }, 0) */
    // .to(".footer", { y: "25%", duration: 2.5, delay: 13 }, 0)
  })

  return tl
}
