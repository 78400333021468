import gsap from "gsap"

gsap.registerEffect({
  name: "counter",
  extendTimeline: true,
  defaults: {
    end: 0,
    duration: 0.5,
    ease: "power1",
    increment: 1,
  },
  effect: (
    targets: HTMLElement,
    config: { duration: number; end: "number"; increment: number; ease: string }
  ) => {
    let tl = gsap.timeline()
    let num = targets[0].innerText.replace(/\,/g, "")
    targets[0].innerText = num
    tl.to(
      targets,
      {
        duration: config.duration,
        innerText: config.end,
        snap: {
          innerText: config.increment,
        },
        modifiers: {
          innerText: function (innerText) {
            return Math.floor(gsap.utils.snap(config.increment, innerText))
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
        ease: config.ease,
      },
      0
    )
    return tl
  },
})

export default function () {
  const mm = gsap.matchMedia()
  let tl: gsap.core.Timeline
  mm.add("(min-width:761px) and (max-width:1919px)", () => {
    tl = gsap
      .timeline()
      .to(
        ".home__fund__wallet > img:nth-of-type(1)",
        { y: "6.5rem", duration: 1, delay: 3.29 },
        0
      )
      .to(
        ".home__fund__wallet > img:nth-of-type(2)",
        { y: "6.5rem", duration: 1, delay: 3.55 },
        0
      )
      .to(
        ".home__fund__wallet > img:nth-of-type(3)",
        { y: "11.5rem", rotate: "-15deg", duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__fund__wallet > img:nth-of-type(4)",
        { y: "9.5rem", rotate: "-15deg", duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__fund__wallet > img:nth-of-type(5)",
        { y: "7rem", rotate: "-15deg", duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__fund__wallet > img:nth-of-type(6)",
        { y: "4.5rem", rotate: "-15deg", duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__fund__wallet > img:nth-of-type(7)",
        { y: "2rem", rotate: "-15deg", duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__fund__illustration > figure:nth-of-type(1)",
        { x: "35rem", duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__fund__illustration > figure:nth-of-type(2)",
        { x: "-35rem", duration: 1.5, delay: 4 },
        0
      )
      .fromTo(
        ".home__fund__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)" },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .set(".home__fund__wallet > img", { opacity: 0, delay: 5.5 }, 0)
      .set(".home__fund__illustration > figure", { opacity: 0, delay: 5.5 }, 0)
      .to(".home__fund__sub", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .counter(
        "div.footer__section__fund h4 span:nth-of-type(1), aside.footer__section__fund h4 span:nth-of-type(1), div.footer__skeleton__fund h4 span:nth-of-type(1)",
        {
          end: 200311,
          duration: 1.2,
          ease: "steps(10)",
        },
        0
      )
      .set(".footer div.footer__section__fund", { autoAlpha: 1 }, 8)
      .to(".home__fund", { autoAlpha: 0 }, 9)
      .from(
        ".footer div.footer__section__fund",
        {
          top: "calc(-50vh - 3.9rem)",
          left: "-122.3rem",
          scale: 1.9,
          duration: 5,
          delay: 8,
        },
        0
      )
      .set(
        ".home__fund aside > div",
        { autoAlpha: 0, duration: 5, delay: 8 },
        0
      )
      .to(".footer", { y: "64%", duration: 5, delay: 8 }, 0)
      .to(".footer__skeleton__fund", { opacity: 0, delay: 9.5 }, 0)
      .to(
        ".footer",
        {
          y: "85%",
          duration: 2,
        },
        13
      )
  })

  mm.add("(min-width:1920px)", () => {
    tl = gsap
      .timeline()
      .to(
        ".home__fund__wallet > img:nth-of-type(1)",
        { y: "6.5rem", duration: 1, delay: 3.29 },
        0
      )
      .to(
        ".home__fund__wallet > img:nth-of-type(2)",
        { y: "6.5rem", duration: 1, delay: 3.55 },
        0
      )
      .to(
        ".home__fund__wallet > img:nth-of-type(3)",
        { y: "11.5rem", rotate: "-15deg", duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__fund__wallet > img:nth-of-type(4)",
        { y: "9.5rem", rotate: "-15deg", duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__fund__wallet > img:nth-of-type(5)",
        { y: "7rem", rotate: "-15deg", duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__fund__wallet > img:nth-of-type(6)",
        { y: "4.5rem", rotate: "-15deg", duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__fund__wallet > img:nth-of-type(7)",
        { y: "2rem", rotate: "-15deg", duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__fund__illustration > figure:nth-of-type(1)",
        { x: "35rem", duration: 1.5, delay: 4 },
        0
      )
      .to(
        ".home__fund__illustration > figure:nth-of-type(2)",
        { x: "-35rem", duration: 1.5, delay: 4 },
        0
      )
      .fromTo(
        ".home__fund__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)" },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .set(".home__fund__wallet > img", { opacity: 0, delay: 5.5 }, 0)
      .set(".home__fund__illustration > figure", { opacity: 0, delay: 5.5 }, 0)
      .to(".home__fund__sub", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .counter(
        "div.footer__section__fund h4 span:nth-of-type(1), aside.footer__section__fund h4 span:nth-of-type(1), div.footer__skeleton__fund h4 span:nth-of-type(1)",
        {
          end: 200311,
          duration: 1.2,
          ease: "steps(10)",
        },
        0
      )
      .set(".footer div.footer__section__fund", { autoAlpha: 1 }, 7)
      .to(".home__fund", { autoAlpha: 0 }, 9)
      .from(
        ".footer div.footer__section__fund",
        {
          top: "calc(-50vh - 3.9rem)",
          left: "-246.2rem",
          scale: 1.9,
          duration: 5,
          delay: 8,
        },
        0
      )
      .set(
        ".home__fund aside > div",
        { autoAlpha: 0, duration: 5, delay: 8 },
        0
      )
      .to(".footer", { y: "64%", duration: 5, delay: 8 }, 0)
      .to(".footer__skeleton__fund", { opacity: 0, delay: 9.5 }, 0)
      .to(
        ".footer",
        {
          y: "85%",
          duration: 2,
        },
        13
      )
  })

  mm.add("(max-width:760px)", () => {
    tl = gsap
      .timeline({ delay: 5 })
      .to(
        ".home__fund__illustration > figure:nth-of-type(1)",
        {
          x: "12rem",
          y: "23rem",
          scale: 0.5,
          duration: 1.5,
          rotate: "75deg",
          delay: 3.2,
        },
        0
      )
      .to(
        ".home__fund__illustration > figure:nth-of-type(2)",
        {
          x: "-7rem",
          y: "7rem",
          scale: 0.5,
          duration: 1.5,
          rotate: "-75deg",
          delay: 3.2,
        },
        0
      )
      .fromTo(
        ".home__fund__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)" },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .set(".home__fund__wallet > img", { opacity: 0, delay: 5.5 }, 0)
      .set(".home__fund__illustration > figure", { opacity: 0, delay: 5.5 }, 0)
      .to(".home__fund__sub", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .counter(
        "div.footer__section__fund h4 span:nth-of-type(1), aside.footer__section__fund h4 span:nth-of-type(1), div.footer__skeleton__fund h4 span:nth-of-type(1)",
        {
          end: 200311,
          duration: 0.8,
          ease: "steps(4)",
        },
        0
      )
      .set(".footer div.footer__section__fund", { autoAlpha: 1 }, 7)
      .to(".home__fund", { autoAlpha: 0 }, 9)
      .from(
        ".footer div.footer__section__fund",
        {
          top: "-185dvh",
          left: "0rem",
          scale: 5.5,
          duration: 5,
          delay: 8,
        },
        0
      )
      .set(".home__fund aside > div", { autoAlpha: 0, delay: 7 }, 0)
      .to(".footer", { y: "15%", duration: 2.5, delay: 10.5 }, 0)
      .to(
        ".footer__section__fund *",
        { color: "transparent", duration: 2.5, delay: 8 },
        0
      )
      .to(".footer__skeleton__fund", { opacity: 0, delay: 11 }, 0)
      .to(
        ".footer",
        {
          y: "20%",
          duration: 2,
        },
        13
      )
  })

  return tl
}
