import gsap from "gsap"

export default function () {
  const mm = gsap.matchMedia()
  let tl: gsap.core.Timeline
  mm.add("(min-width:761px) and (max-width:1919px)", () => {
    tl = gsap
      .timeline()
      .from(
        ".home__cards aside *",
        {
          color: "transparent",
        },
        10
      )
      .to(
        ".home__virtual__illustration [quote1]",
        { x: "-50vw", duration: 2, delay: 4.5 },
        0
      )
      .to(
        ".home__virtual__illustration [quote2]",
        { x: "50vw", duration: 2, delay: 4.5 },
        0
      )
      .to(
        ".home__cards .figures > figure svg",
        { opacity: 0, scale: 0.35, duration: 2, delay: 4 },
        0
      )
      .to(
        ".home__cards .figures > figure",
        { background: "transparent", delay: 6.5, duration: 2 },
        0
      )
      .to(
        ".home__cards .figures > figure:nth-of-type(1)",
        {
          x: "14.2rem",
          y: "1.6rem",
          scale: 0.9,
          duration: 2,
          delay: 4.5,
          opacity: 0.5,
        },
        0
      )
      .to(
        ".home__cards .figures > figure:nth-of-type(2)",
        {
          x: "-22rem",
          y: "-2.7rem",
          scale: 0.9,
          duration: 2,
          delay: 4.5,
          opacity: 0.5,
        },
        0
      )
      .to(
        ".home__cards .figures > figure:nth-of-type(3)",
        {
          x: "18.6rem",
          y: "3.1rem",
          scale: 0.9,
          duration: 2,
          delay: 4.5,
          opacity: 0.5,
        },
        0
      )
      .to(
        ".home__virtual__illustration [card]",
        {
          rotate: 0,
          y: "+=5.5rem",
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__cards aside",
        {
          rotate: 0,
          y: "10.5rem",
          x: "12rem",
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__virtual__money div:nth-of-type(1), .home__virtual__money div:nth-of-type(3), .home__virtual__ice img:nth-of-type(1)",
        {
          autoAlpha: 0,
          x: "15vw",
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__virtual__dollar img",
        {
          autoAlpha: 0,
          rotate: () => `${(Math.random() - 0.5) * 120}vw`,
          y: () => `${Math.random() * 25 + 10}vh`,
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__virtual__money div:nth-of-type(2), .home__virtual__ice img:nth-of-type(2)",
        {
          autoAlpha: 0,
          x: "-15vw",
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__virtual:nth-of-type(4) .home__virtual__illustration [card]",
        {
          clipPath: "inset(0% 0% 30% 0%)",
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__virtual__illustration [card]",
        {
          clipPath: "inset(0% 0% 100% 0%)",
          yPercent: 100,
          duration: 1.5,
          delay: 5.5,
        },
        0
      )
      .fromTo(
        ".home__cards__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)", yPercent: 0 },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .fromTo(
        ".home__cards__head:nth-of-type(2) i",
        { clipPath: "inset(-5% 0% 0% 0%)", yPercent: 0 },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .fromTo(
        ".home__cards__head:nth-of-type(3) i",
        { clipPath: "inset(-5% 0% 0% 0%)", yPercent: 0 },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .fromTo(
        ".home__cards__head:nth-of-type(4) i",
        { clipPath: "inset(-5% 0% 0% 0%)", yPercent: 0 },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .to(".home__cards__subs", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .set(".footer div.footer__section__virtual", { autoAlpha: 1 }, 8)
      .set(".home__cards aside > div", { autoAlpha: 0, delay: 8 }, 0)
      .to(".home__cards", { autoAlpha: 0 }, 9)
      .from(
        ".footer div.footer__section__virtual",
        {
          top: "calc(-50vh + 24.1rem)",
          left: "-24.7rem",
          scale: 3,
          duration: 5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__virtual, .footer div.footer__section__virtual * ",
        {
          background: "transparent",
          color: "transparent",
          duration: 1,
          delay: 12,
        },
        0
      )
      .to(".footer", { y: "25%", duration: 5, delay: 8 }, 0)
      .to(".footer__skeleton__virtual", { opacity: 1, delay: 12.5 }, 0)
      .to(".footer__skeleton__virtual2", { opacity: 0, delay: 11 }, 0)
      .to(
        ".footer",
        {
          y: "85%",
          duration: 2,
        },
        13
      )
  })

  mm.add("(min-width:1920px)", () => {
    tl = gsap
      .timeline()
      .from(
        ".home__cards aside *",
        {
          color: "transparent",
        },
        10
      )
      .to(
        ".home__virtual__illustration [quote1]",
        { x: "-50vw", duration: 2, delay: 4.5 },
        0
      )
      .to(
        ".home__virtual__illustration [quote2]",
        { x: "50vw", duration: 2, delay: 4.5 },
        0
      )
      .to(
        ".home__cards .figures > figure svg",
        { opacity: 0, scale: 0.35, duration: 2, delay: 4 },
        0
      )
      .to(
        ".home__cards .figures > figure",
        { background: "transparent", delay: 6.5, duration: 2 },
        0
      )
      .to(
        ".home__cards .figures > figure:nth-of-type(1)",
        {
          x: "14.2rem",
          y: "1.6rem",
          scale: 0.9,
          duration: 2,
          delay: 4.5,
          opacity: 0.5,
        },
        0
      )
      .to(
        ".home__cards .figures > figure:nth-of-type(2)",
        {
          x: "-22rem",
          y: "-2.7rem",
          scale: 0.9,
          duration: 2,
          delay: 4.5,
          opacity: 0.5,
        },
        0
      )
      .to(
        ".home__cards .figures > figure:nth-of-type(3)",
        {
          x: "18.6rem",
          y: "3.1rem",
          scale: 0.9,
          duration: 2,
          delay: 4.5,
          opacity: 0.5,
        },
        0
      )
      .to(
        ".home__virtual__illustration [card]",
        {
          rotate: 0,
          y: "+=5.5rem",
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__cards aside",
        {
          rotate: 0,
          y: "10.5rem",
          x: "12rem",
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__virtual__money div:nth-of-type(1), .home__virtual__money div:nth-of-type(3), .home__virtual__ice img:nth-of-type(1)",
        {
          autoAlpha: 0,
          x: "15vw",
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__virtual__dollar img",
        {
          autoAlpha: 0,
          rotate: () => `${(Math.random() - 0.5) * 120}vw`,
          y: () => `${Math.random() * 25 + 10}vh`,
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__virtual__money div:nth-of-type(2), .home__virtual__ice img:nth-of-type(2)",
        {
          autoAlpha: 0,
          x: "-15vw",
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__virtual:nth-of-type(4) .home__virtual__illustration [card]",
        {
          clipPath: "inset(0% 0% 30% 0%)",
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__virtual__illustration [card]",
        {
          clipPath: "inset(0% 0% 100% 0%)",
          yPercent: 100,
          duration: 1.5,
          delay: 5.5,
        },
        0
      )
      .fromTo(
        ".home__cards__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)", yPercent: 0 },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .fromTo(
        ".home__cards__head:nth-of-type(2) i",
        { clipPath: "inset(-5% 0% 0% 0%)", yPercent: 0 },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .fromTo(
        ".home__cards__head:nth-of-type(3) i",
        { clipPath: "inset(-5% 0% 0% 0%)", yPercent: 0 },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .fromTo(
        ".home__cards__head:nth-of-type(4) i",
        { clipPath: "inset(-5% 0% 0% 0%)", yPercent: 0 },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .to(".home__cards__subs", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .set(".footer div.footer__section__virtual", { autoAlpha: 1 }, 8)
      .set(".home__cards aside > div", { autoAlpha: 0, delay: 8 }, 0)
      .to(".home__cards", { autoAlpha: 0 }, 9)
      .from(
        ".footer div.footer__section__virtual",
        {
          top: "calc(-50vh + 24.1rem)",
          left: "-86.2rem",
          scale: 3,
          duration: 5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__virtual, .footer div.footer__section__virtual * ",
        {
          background: "transparent",
          color: "transparent",
          duration: 1,
          delay: 12,
        },
        0
      )
      .to(".footer", { y: "25%", duration: 5, delay: 8 }, 0)
      .to(".footer__skeleton__virtual", { opacity: 1, delay: 12.5 }, 0)
      .to(".footer__skeleton__virtual2", { opacity: 0, delay: 11 }, 0)
      .to(
        ".footer",
        {
          y: "85%",
          duration: 2,
        },
        13
      )
  })

  mm.add("(max-width:760px)", () => {
    tl = gsap
      .timeline({ delay: 5 })
      .from(
        ".home__cards aside *",
        {
          color: "transparent",
        },
        10
      )
      .to(
        ".home__cards .figures > figure:nth-of-type(1)",
        {
          x: "14.2rem",
          y: "1.6rem",
          scale: 0.9,
          duration: 2,
          delay: 4.5,
          opacity: 0,
        },
        0
      )
      .to(
        ".home__cards .figures > figure:nth-of-type(2)",
        {
          x: "-22rem",
          y: "-2.7rem",
          scale: 0.9,
          duration: 2,
          delay: 4.5,
          opacity: 0,
        },
        0
      )
      .to(
        ".home__cards .figures > figure:nth-of-type(3)",
        {
          x: "18.6rem",
          y: "3.1rem",
          scale: 0.9,
          duration: 2,
          delay: 4.5,
          opacity: 0,
        },
        0
      )
      .to(
        ".home__virtual__illustration [card]",
        {
          rotate: 0,
          y: "+=5.5rem",
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__cards aside",
        {
          rotate: 0,
          y: "4.5rem",
          x: "14.35rem",
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__virtual__money div:nth-of-type(1), .home__virtual__money div:nth-of-type(3), .home__virtual__ice img:nth-of-type(1)",
        {
          autoAlpha: 0,
          x: "15vw",
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__virtual__dollar img",
        {
          autoAlpha: 0,
          rotate: () => `${(Math.random() - 0.5) * 120}vw`,
          y: () => `${Math.random() * 25 + 10}vh`,
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__virtual__money div:nth-of-type(2), .home__virtual__ice img:nth-of-type(2)",
        {
          autoAlpha: 0,
          x: "-15vw",
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__virtual:nth-of-type(4) .home__virtual__illustration [card]",
        {
          clipPath: "inset(0% 0% 30% 0%)",
          x: "0rem",
          duration: 1.5,
          delay: 4,
        },
        0
      )
      .to(
        ".home__virtual__illustration [card]",
        {
          clipPath: "inset(0% 0% 100% 0%)",
          y: "+=16.8256rem",
          duration: 1.5,
          delay: 5.5,
        },
        0
      )
      .fromTo(
        ".home__cards__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)", yPercent: 0 },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .fromTo(
        ".home__cards__head:nth-of-type(2) i",
        { clipPath: "inset(-5% 0% 0% 0%)", yPercent: 0 },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .fromTo(
        ".home__cards__head:nth-of-type(3) i",
        { clipPath: "inset(-5% 0% 0% 0%)", yPercent: 0 },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .fromTo(
        ".home__cards__head:nth-of-type(4) i",
        { clipPath: "inset(-5% 0% 0% 0%)", yPercent: 0 },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .to(".home__cards__subs", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .set(".footer div.footer__section__virtual", { autoAlpha: 1 }, 8)
      .set(".home__cards aside > div", { autoAlpha: 0, delay: 8 }, 0)
      .to(".home__cards", { autoAlpha: 0 }, 9)
      .from(
        ".footer div.footer__section__virtual",
        {
          top: "calc(-149vh + 21rem)",
          left: "87rem",
          scale: 8.25,
          duration: 5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__virtual, .footer div.footer__section__virtual * ",
        {
          background: "transparent",
          duration: 1,
          delay: 12,
        },
        0
      )
      .to(".footer", { y: "5%", duration: 2.5, delay: 10.5 }, 0)
      .set(".footer__section__virtual *", { color: "transparent", delay: 8 }, 0)
      .to(".footer__skeleton__virtual", { opacity: 1, delay: 12.5 }, 0)
      .to(".footer__skeleton__virtual2", { opacity: 0, delay: 12 }, 0)
      .to(
        ".footer",
        {
          y: "25%",
          duration: 2,
        },
        13
      )
  })

  return tl
}
