import { Draggable } from "gsap/all"
import { $App } from ".."
import gsap from "gsap"
import _Draggable from "gsap/Draggable"

gsap.registerPlugin(Draggable)

export default class Footer {
  app: $App
  element: any
  timeline: gsap.core.Timeline
  isMenuOpen: boolean
  constructor(app: $App) {
    this.app = app
    this.element = window.$(".page__footer")
    if (this.app.template !== "home") this.create()
    else gsap.set("footer", { display: "block" })
  }

  create() {
    gsap.set("footer", { display: "none" })
    Draggable.create(".page__footer__doodles > *", {
      inertia: true,
    })
  }

  resize() {}

  destroy() {}

  navigate() {
    if (this.app.template !== "home") this.create()
    else gsap.set("footer", { display: "block" })
  }
}
