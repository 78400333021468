export const images = [
  "images/card.png",
  "images/card01.png",
  "images/card02.png",
  "images/cardice.png",
  "images/cardicemobile.png",
  "images/cardmoney.png",
  "images/cardspend.png",
  "images/cash01.png",
  "images/cash02.png",
  "images/cash03.png",
  "images/cash04.png",
  "images/cash05.png",
  "images/cloud01.png",
  "images/cloud02.png",
  "images/cloud03.png",
  "images/cloud04.png",
  "images/dollar01.png",
  "images/dollar02.png",
  "images/dollar03.png",
  "images/dollar04.png",
  "images/dollar05.png",
  "images/dollar06.png",
  "images/dollar07.png",
  "images/dollar08.png",
  "images/dollar09.png",
  "images/faqsmedia01.png",
  "images/feature01.png",
  "images/feature02sub01.png",
  "images/feature02sub02.png",
  "images/feature02sub03.png",
  "images/feature02sub04.png",
  "images/feature02sub05.png",
  "images/feature03sub01.png",
  "images/feature03sub02.png",
  "images/feature03sub03.png",
  "images/feature03sub04.png",
  "images/feature03sub05.png",
  "images/feature04sub01.png",
  "images/feature04sub02.png",
  "images/feature04sub03.png",
  "images/feature04sub04.png",
  "images/feature04sub05.png",
  "images/feature04sub06.png",
  "images/feature05.png",
  "images/feature05icon01.png",
  "images/feature05icon02.png",
  "images/feature05icon03.png",
  "images/feature05sub01.png",
  "images/feature05sub02.png",
  "images/feature05sub03.png",
  "images/feature06sub01.png",
  "images/feature06sub02.png",
  "images/feature06sub011.png",
  "images/feature06sub012.png",
  "images/feature06sub013.png",
  "images/feature06sub021.png",
  "images/feature06sub022.png",
  "images/feature06sub023.png",
  "images/flutterwave.png",
  "images/footermedia01.png",
  "images/footermedia02.png",
  "images/footermedia03.png",
  "images/ice01.png",
  "images/ice02.png",
  "images/ice03.png",
  "images/icon01.png",
  "images/icon02.png",
  "images/icon03.png",
  "images/logopurple.png",
  "images/memoji01.png",
  "images/memoji02.png",
  "images/memoji03.png",
  "images/memoji04.png",
  "images/nyatwa.png",
  "images/nyatwa02.png",
  "images/paychangu.png",
  "images/people.png",
  "images/phone.png",
]
