import gsap from "gsap"

export default function () {
  const mm = gsap.matchMedia()
  let tl: gsap.core.Timeline
  mm.add("(min-width:761px) and (max-width:1919px)", () => {
    tl = gsap
      .timeline()
      .from(
        ".home__receive aside *",
        {
          color: "transparent",
        },
        10
      )
      .to(
        ".home__receive__illustration > img:nth-of-type(1)",
        { x: "35rem", y: "4.7rem", scale: 0, duration: 2, delay: 4.5 },
        0
      )
      .to(
        ".home__receive__illustration > img:nth-of-type(2)",
        { x: "-37.8rem", y: "4.4rem", scale: 0, duration: 2, delay: 4.5 },
        0
      )
      .fromTo(
        ".home__receive__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)" },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .to(".home__receive__sub", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .set(".footer div.footer__section__receive", { autoAlpha: 1 }, 8)
      .set(".home__receive aside > div", { autoAlpha: 0, delay: 8 }, 0)
      .to(".home__receive", { autoAlpha: 0 }, 9)
      .from(
        ".footer div.footer__section__receive",
        {
          top: "calc(-50vh + 9.5rem)",
          left: "-39.7rem",
          scale: 7,
          duration: 5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__receive, .footer div.footer__section__receive * ",
        {
          background: "transparent",
          color: "transparent",
          duration: 1,
          delay: 12,
        },
        0
      )
      .to(".footer", { y: "50%", duration: 5, delay: 8 }, 0)
      .to(".footer__skeleton__receive", { opacity: 0, delay: 11 }, 0)
      .to(
        ".footer",
        {
          y: "85%",
          duration: 2,
        },
        13
      )
  })

  mm.add("(min-width:1920px)", () => {
    tl = gsap
      .timeline()
      .from(
        ".home__receive aside *",
        {
          color: "transparent",
        },
        10
      )
      .to(
        ".home__receive__illustration > img:nth-of-type(1)",
        { x: "35rem", y: "4.7rem", scale: 0, duration: 2, delay: 4.5 },
        0
      )
      .to(
        ".home__receive__illustration > img:nth-of-type(2)",
        { x: "-37.8rem", y: "4.4rem", scale: 0, duration: 2, delay: 4.5 },
        0
      )
      .fromTo(
        ".home__receive__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)" },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .to(".home__receive__sub", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .set(".footer div.footer__section__receive", { autoAlpha: 1 }, 8)
      .set(".home__receive aside > div", { autoAlpha: 0, delay: 8 }, 0)
      .to(".home__receive", { autoAlpha: 0 }, 9)
      .from(
        ".footer div.footer__section__receive",
        {
          top: "calc(-50vh + 9.5rem)",
          left: "-101.388rem",
          scale: 7,
          duration: 5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__receive, .footer div.footer__section__receive * ",
        {
          background: "transparent",
          color: "transparent",
          duration: 1,
          delay: 12,
        },
        0
      )
      .to(".footer", { y: "50%", duration: 5, delay: 8 }, 0)
      .to(".footer__skeleton__receive", { opacity: 0, delay: 11 }, 0)
      .to(
        ".footer",
        {
          y: "85%",
          duration: 2,
        },
        13
      )
  })

  mm.add("(max-width:760px)", () => {
    tl = gsap
      .timeline({ delay: 5 })
      .from(
        ".home__receive aside *",
        {
          color: "transparent",
        },
        10
      )
      .to(
        ".home__receive__illustration > img:nth-of-type(3)",
        { x: "-11rem", y: "17.25rem", scale: 0, duration: 2, delay: 4.5 },
        0
      )
      .to(
        ".home__receive__illustration > img:nth-of-type(4)",
        { x: "7.4rem", y: "10.05rem", scale: 0, duration: 2, delay: 4.5 },
        0
      )
      .to(
        ".home__receive__illustration > img:nth-of-type(5)",
        { y: "-9.75rem", scale: 0, duration: 2, delay: 4.5 },
        0
      )
      .fromTo(
        ".home__receive__head:nth-of-type(1) i",
        { clipPath: "inset(-5% 0% 0% 0%)" },
        {
          clipPath: "inset(100% 0% 0% 0%)",
          duration: 1,
          delay: 5.5,
          yPercent: -100,
          stagger: { amount: 1 },
        },
        0
      )
      .to(".home__receive__sub", { opacity: 0, duration: 0.6, delay: 5.75 }, 0)
      .set(".footer div.footer__section__receive", { autoAlpha: 1 }, 8)
      .set(".home__receive aside > div", { autoAlpha: 0, delay: 8 }, 0)
      .to(".home__receive", { autoAlpha: 0 }, 9)
      .from(
        ".footer div.footer__section__receive",
        {
          top: "-163dvh",
          left: "45rem",
          scale: 18,
          duration: 5,
          delay: 8,
        },
        0
      )
      .from(
        ".footer div.footer__section__receive, .footer div.footer__section__receive * ",
        {
          background: "transparent",
          duration: 1,
          delay: 12,
        },
        0
      )
      .to(".footer", { y: "10%", duration: 2.5, delay: 10.5 }, 0)
      .set(".footer__section__receive *", { color: "transparent", delay: 8 }, 0)
      .to(".footer__skeleton__receive", { opacity: 0, delay: 11 }, 0)
      .to(
        ".footer",
        {
          y: "20%",
          duration: 2,
        },
        13
      )
  })

  return tl
}
