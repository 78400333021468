import gsap from "gsap"

export function navOpen() {
  return gsap
    .timeline({
      paused: true,
      defaults: { ease: "power2.inOut", duration: 0.35 },
    })
    .to(".header__popup span", {
      top: "4.8rem",
    })
    .to(
      ".header__popup",
      {
        background: "#836ee7",
      },
      0.35
    )
    .to(
      ".header__popup span",
      {
        background: "#fff",
      },
      0.35
    )

    .fromTo(
      ".header__menu__icons *",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.65,
        ease: "ease.out",
        stagger: { amount: 0.2 },
      },
      0.3
    )
    .fromTo(
      ".header__menu li",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        ease: "ease.out",
      },
      0.35
    )
    .fromTo(
      ".header__menu",
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 0.45,
        ease: "ease.inout",
      },
      0.3
    )
    .to(
      "footer",
      {
        display: "none",
      },
      0.1
    )
    .set(
      ".header__popup span:nth-of-type(2)",
      {
        opacity: 0,
      },
      0.35
    )
    .set(
      ".header__popup span:nth-of-type(2)",
      {
        opacity: 0,
      },
      0.35
    )
    .to(
      ".header__popup span:nth-of-type(1)",
      {
        rotate: "45deg",
      },
      0.35
    )
    .to(
      ".header__popup span:nth-of-type(3)",
      {
        rotate: "-45deg",
      },
      0.35
    )
}
